@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-dm text-title;
    }

    h1 {
        @apply font-black text-[2.5rem];
    }
    
    h2 {
        @apply font-bold text-4xl;
    }

    h3 {
        @apply font-semibold text-3xl;
    }

    h4 {
        @apply text-2xl;
    }

    h5 {
        @apply font-medium text-xl;
    }

    h6 {
        @apply font-medium text-lg
    }
}

@layer utilities {
}
