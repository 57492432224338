input:autofill {
    box-shadow: 0 0 0px 1000px #ffadde33 inset;
    background-color: transparent;
}

body {
    margin: 0;
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body * {
    box-sizing: border-box;
}

body {
    color: #212121;
}

/* @media (prefers-color-scheme: dark) {
    body {
        color: #efefef;
    }
} */
